import { Cart, CommerceProperty } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g.d';

enum ePropsValue {
    String = 'STRING',
    Integer = 'INTEGER',
    Boolean = 'BOOLEAN',
    Decimal = 'DECIMAL'
}

type ExtensionPropsValue = ePropsValue.Boolean | ePropsValue.Integer | ePropsValue.String | ePropsValue.Decimal | null;

const IS_BUSINESS_ACCOUNT_KEY = 'IsBusinessAccount';
const CUST_HOLD_STATUS_KEY = 'CustHoldStatus';
const CUST_ACCOUNT_STATUS_KEY = 'CustAccountStatus';


const isShareholder = (cart: Cart): boolean => {
    console.log(cart)
    const { CustomerId, ExtensionProperties } = cart;
    const isBusinessAccountProperty = ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === IS_BUSINESS_ACCOUNT_KEY);
    if (CustomerId !==undefined)
    {
        return CustomerId !== null && CustomerId!.length > 0 && ((isBusinessAccountProperty && isBusinessAccountProperty?.Value?.BooleanValue) || false);
    }
    else
    {
        return false;
    }
};

const isCasual = (cart: Cart): boolean => {
    const { CustomerId, ExtensionProperties } = cart;
    const isBusinessAccountProperty = ExtensionProperties!.find((prop: CommerceProperty) => prop.Key === IS_BUSINESS_ACCOUNT_KEY);
    if (CustomerId !==undefined)
    {
        return CustomerId !== null && CustomerId!.length > 0 && ((isBusinessAccountProperty && !(isBusinessAccountProperty?.Value?.BooleanValue)) || false);
    }
    else{
        return false;
    }
};

const isGuest = (cart: Cart): boolean => {
    const { CustomerId } = cart;
    if (CustomerId !==undefined)
    {
    return !(CustomerId!.length > 0);
    }
    else{
        return true;
    }

};

const findExtensionProperty = (ExtensionProperties: CommerceProperty[], key: string): CommerceProperty | undefined => {
    return ExtensionProperties.find((prop: CommerceProperty) => prop.Key === key);
};

// CustHoldStatus for the customer is not set to "Tax invoice" or "All"
const isShareholderAccountNotOnHold = (ExtensionProperties: CommerceProperty[]): boolean => {
    const custHoldStatusProp = findExtensionProperty(ExtensionProperties, CUST_HOLD_STATUS_KEY);
    const onHoldStatus = custHoldStatusProp ? custHoldStatusProp.Value!.StringValue! : '';
    return !['Tax invoice', 'All'].includes(onHoldStatus);
};

// CustAccountStatus for the customer is set to "Active" or "Monitored"
const isShareholderAccountNotInactive = (ExtensionProperties: CommerceProperty[]): boolean => {
    const custAccountStatusProp = findExtensionProperty(ExtensionProperties, CUST_ACCOUNT_STATUS_KEY);
    const accountStatus = custAccountStatusProp ? custAccountStatusProp.Value!.StringValue! : '';
    return ['Active', 'Monitored'].includes(accountStatus);
};

// CustAccountStatus for the customer is set to "Off-boarding" or "Suspended"
const isShareholderAccountDisabled = (ExtensionProperties: CommerceProperty[]): boolean => {
    const custAccountStatusProp = findExtensionProperty(ExtensionProperties, CUST_ACCOUNT_STATUS_KEY);
    const accountStatus = custAccountStatusProp ? custAccountStatusProp.Value!.StringValue! : '';
    return ['Off-boarding', 'Suspended'].includes(accountStatus);
};

// CustAccountStatus for the customer is set to specifically to "Inactive"
const isShareholderAccountInactive = (ExtensionProperties: CommerceProperty[]): boolean => {
    const custAccountStatusProp = findExtensionProperty(ExtensionProperties, CUST_ACCOUNT_STATUS_KEY);
    const accountStatus = custAccountStatusProp ? custAccountStatusProp.Value!.StringValue! : '';
    return ['Inactive'].includes(accountStatus);
};

const hasEcommerceAccess = (userContactDetails: any): boolean => {
    return userContactDetails?.CustomerAccounts.find((account: any) => account.IsActive === true).Permissions[0]?.Authorities.find(
        (prop: any) => prop.Authority === 'EcommerceAccess'
    );
};

/**
 * Get the value of an ExtensionProperty (EP)
 * @param extensionProperties The EP used to search for property
 * @param keyValue The key value to search
 * @param typeValue The type value to return
 * @returns The value from EP or EP Object if no typeValue is passed
 */
const getExtensionProperty = (extensionProperties: CommerceProperty[], keyValue: string, typeValue: ExtensionPropsValue = null): any => {
    const extensionProperty = extensionProperties.find((prop: CommerceProperty) => prop.Key === keyValue);

    if (typeValue === null || !extensionProperty) {
        return extensionProperty;
    }

    switch (typeValue) {
        case ePropsValue.String:
            return extensionProperty.Value!.StringValue;
        case ePropsValue.Boolean:
            return extensionProperty.Value!.BooleanValue;
        case ePropsValue.Integer:
            return extensionProperty.Value!.IntegerValue;
        case ePropsValue.Decimal:
            return extensionProperty.Value!.DecimalValue;
        default:
            return null;
    }
};

const isValidColour = (str: string | undefined): string | void => {
    if (str === undefined || str.match(/^#[a-f0-9]{6}$/i) === null) {
        return;
    }
    return str;
};

export {
    isShareholder,
    isCasual,
    isGuest,
    isShareholderAccountNotInactive,
    isShareholderAccountDisabled,
    isShareholderAccountInactive,
    isShareholderAccountNotOnHold,
    hasEcommerceAccess,
    ePropsValue,
    getExtensionProperty,
    isValidColour
};
